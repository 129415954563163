import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import nodeQuery from '@aurora/shared-client/components/nodes/NodeView.query.graphql';
import type {
  NodeViewQuery,
  NodeViewQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import {
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext, useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ItemType, NodeViewVariant } from '../../../types/enums';
import ItemView from '../../common/ItemView/ItemView';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC } from '../../common/Widget/types';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useTranslation from '../../useTranslation';
import type { NodeListWidget } from '../types';
import localStyles from './NodeInformationWidget.module.css';

export interface NodeInformationWidgetProps extends NodeListWidget {
  /**
   * View variant to use for the card.
   */
  viewVariant?: ItemViewTypeAndProps<ItemType.NODE, NodeViewVariant.CARD>;
  /**
   * The horizontal alignment of content within the widget.
   */
  alignment?: TextAlignment;
  /**
   * Whether to add title in the Widget.
   */
  useWidgetTitle?: boolean;
}

const defaultProps: NodeInformationWidgetProps = {
  viewVariant: {
    type: NodeViewVariant.CARD,
    props: {
      useNodeAvatar: true,
      useNodeTitle: false,
      useNodeDescription: true,
      useNodeOwners: true,
      useNodeCreationDate: true,
      useNodeLatestActivityTime: true,
      useNodeMembersCount: true,
      useNodeMembershipType: {
        useNodeType: true,
        hideOpen: false
      },
      useNodeMembersCountText: true,
      useNodeTopicsCount: false,
      useClickableCard: false,
      useNodePostCount: true,
      useNodeAction: true,
      useNodeFollowersCount: true,
      useTextDescription: false
    }
  },
  useWidgetTitle: true,
  alignment: TextAlignment.CENTER
};

export function getFinalProps(props: NodeInformationWidgetProps): NodeInformationWidgetProps {
  return merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}

/**
 * Node View - Information
 *
 * @author Shalin Amin
 */
const NodeInformationWidget: WidgetFC<NodeInformationWidgetProps> = props => {
  const { isVisible, ...rest } = props;
  const finalProps: NodeInformationWidgetProps = getFinalProps(rest);
  const { viewVariant, useWidgetTitle, alignment } = finalProps;
  const { contextNode } = useContext(AppContext);
  const queryResult = useEntityViewQuery<ItemType.NODE, NodeViewQuery, NodeViewQueryVariables>(
    module,
    ItemType.NODE,
    viewVariant,
    nodeQuery,
    {
      variables: {
        id: contextNode.id,
        useNodeParent: true,
        useMembershipInformation: true,
        useTextDescriptionForNode: false,
        useNodeLatestActivityTime: true
      }
    }
  );

  const { data: queryData, loading } = queryResult;
  useEffect(() => {
    if (!loading) {
      isVisible(!!queryData?.coreNode);
    }
  }, [queryData, isVisible, loading]);

  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_INFORMATION_WIDGET
  );

  const cx = useClassNameMapper(localStyles);

  if (textLoading) {
    return null;
  }

  return (
    <EditableWidget<NodeInformationWidgetProps> props={finalProps}>
      <QueryHandler queryResult={queryResult}>
        {(): React.ReactNode => {
          const {
            data: { coreNode }
          } = queryResult;

          const decoratedViewVariant: ItemViewTypeAndProps<ItemType.NODE, NodeViewVariant.CARD> = {
            ...viewVariant
          };
          decoratedViewVariant.props.header = useWidgetTitle
            ? function EntityViewHeader(): React.ReactElement {
                return (
                  <h3 className={cx('mb-0 h5')}>
                    {formatMessage('WidgetTitle', { title: coreNode.title })}
                  </h3>
                );
              }
            : null;

          return (
            <ItemView
              entity={coreNode}
              type={ItemType.NODE}
              variant={decoratedViewVariant}
              className={cx(`lia-text-alignment-${alignment}`)}
            />
          );
        }}
      </QueryHandler>
    </EditableWidget>
  );
};

export default NodeInformationWidget;
